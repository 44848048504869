.ReviewPage {
  text-align: center;
}

.ReviewPage-Flex-Container {
  display: flex;
}

.ReviewPage-Selfie {
  text-align: left;
  padding-left: 3%;
  flex-direction: row;
  flex-grow: 1;
  border-bottom: solid 1px black;
  position: relative;
}

.ReviewPage-Selfie-Col {
  display: inline-block;
}

.ReviewPage-IdFace-Col {
  display: inline-block;
}

.ReviewPage-IdFace {
  text-align: left;
  padding-left: 3%;
  flex-direction: row;
  flex-grow: 1;
  border-bottom: solid 1px black;
  border-left: solid 1px black;
  position: relative;
}

.ReviewPage-Bottom-Container {
  text-align: left;
  padding-left: 3%;
  border-bottom: solid 1px black;
  position: relative;
}

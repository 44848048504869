.ClientReviewPage {
  text-align: center;
}

.ClientReviewPage-Flex-Container {
  display: flex;
}

.ClientReviewPage-Selfie {
  text-align: left;
  padding-left: 3%;
  flex-direction: row;
  flex-grow: 1;
  border-bottom: solid 1px black;
  position: relative;
}

.ClientReviewPage-Selfie-Col {
  display: inline-block;
}

.ClientReviewPage-IdFace-Col {
  display: inline-block;
}

.ClientReviewPage-IdFace {
  text-align: left;
  padding-left: 3%;
  flex-direction: row;
  flex-grow: 1;
  border-bottom: solid 1px black;
  border-left: solid 1px black;
  position: relative;
}

.ClientReviewPage-Bottom-Container {
  text-align: left;
  padding-left: 3%;
  border-bottom: solid 1px black;
  position: relative;
}

.LoginPage-Container {
  padding: 5rem;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.LoginPage-Input {
  margin-top: 15px;
}

::-webkit-input-placeholder {
  font-size: small;
}

.LoginPage-Title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.LoginPage-Button {
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 5px;
}

.LoginPage-Hidden {
  display: hidden;
}

.LoginPage-Error {
  color: red;
  display: inline;
}
